import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getNews } from '../Service/Api';

const News = () => {
  
    const [data, setData] = useState([]);
    const [filterData, setFilteredData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("All");
    const [selectedMonth, setSelectedMonth] = useState("All");
    const [monthOptions, setMonthOptions] = useState(["All"]);
    const [yearRanges, setYearRanges] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const monthsOfYear = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
      ];
    const handleMonth = (month) => {
        return monthsOfYear[month - 1];
      };

      const emptyArray = [
        { title: "News Title", day: "DD", month: "MM", description: "Stay tuned for latest updates" },
        { title: "News Title", day: "DD", month: "MM", description: "Stay tuned for latest updates" },
    
      ];

    useEffect(() => {
       const fetchData = async () => {
          const galleryData = await getNews();
          setData(galleryData);
          const uniqueYears = Array.from(
             new Set(galleryData.flatMap((item) => {
                if (item.fromDate) {
                   const year = new Date(item.fromDate).getFullYear();
                   return [year];
                } else {
                   return [];
                }
             }))
          );
          const uniqueYearRanges = uniqueYears.map(year => `${year}`);
          uniqueYearRanges.sort((a, b) => b - a);
          setYearRanges(uniqueYearRanges);
 
          const uniquemonth = Array.from(
             new Set(galleryData.flatMap((item) => {
                if (item.fromDate) {
                   const month = new Date(item.fromDate).getMonth() + 1;
                   return [month];
                } else {
                   return [];
                }
             }))
          );
          const uniqueMonths = uniquemonth.map(month => `${month}`);
          uniqueMonths.sort((a, b) => b - a);
          setMonthOptions(uniqueMonths);
       };
       fetchData();
    }, []);
 
    useEffect(() => {
       let filteredData = data;
       if (selectedYear !== "All") {
          filteredData = filteredData.filter((item) => {
             if (item.fromDate && item.fromDate.trim() !== "") {
                const year = new Date(item.fromDate).getFullYear();
                const month = new Date(item.fromDate).getMonth() + 1;
                return parseInt(selectedYear) === year && (selectedMonth === "All" || parseInt(selectedMonth) === month);
             } else {
                return false;
             }
          });
       }
       if (selectedMonth !== "All") {
          filteredData = filteredData.filter((item) => {
             if (item.fromDate && item.fromDate.trim() !== "") {
                const month = new Date(item.fromDate).getMonth() + 1;
                return parseInt(selectedMonth) === month;
             } else {
                return false;
             }
          });
       }
       if (searchQuery) {
          filteredData = filteredData.filter((item) =>
             item.title.toLowerCase().includes(searchQuery.toLowerCase())
          );
       }
       setFilteredData(filteredData);
    }, [selectedYear, selectedMonth, searchQuery, data]);
 
  return (
    <>
      <div className="innerslide">
        <ul className="breadcrumb">
        <li><Link to="/home"> Home</Link> </li>
          <li>News</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>News</h1>
            </div>
          </div>
          <div className="row tabs-dynamic">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
                     <div className="count-val">
                        <p>Total Count:{filterData.length} </p>
                     </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
                     <div className="month-selection">
                        <select
                           value={selectedYear}
                           onChange={(e) => setSelectedYear(e.target.value)}
                           className="yearSelection">
                           <option value={"All"}>All Year</option>
                           {yearRanges.map((yr) => (
                              <option key={yr} value={yr}>{yr}</option>
                           ))}
                        </select>
                     </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
                     <div className="month-selection">
                        <select
                           value={selectedMonth}
                           onChange={(e) => setSelectedMonth(e.target.value)}
                           className="monthSelect">
                           <option value={"All"}>All Month</option>
                           {monthOptions.map((month, index) => (
                              <option key={index} value={month}>{month}</option>
                           ))}
                        </select>
                     </div>
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-12">
                     <div className="searchBox">
                        <input
                           type="text"
                           id="myInput"
                           name="name"
                           value={searchQuery}
                           onChange={(e) => setSearchQuery(e.target.value)}
                           placeholder="Search Here.."
                        />
                     </div>
                  </div>
               </div>
          <div className="row years year2024 showYear">
            <div className="newscountbox">
            {data && data.length > 0 ? data.map((item, index) => (
                <div key={index} className="newsCount">
                  <div className="news-block">
                    <div className="news-head">
                      <h4>{new Date(item.fromDate).getDate()} | {handleMonth(new Date(item.fromDate).getMonth())}</h4>
                      <h3>{item.title}</h3>
                    </div>
                    <div className="news-content">
                      <p>{item.description}</p>
                    </div>
                    <div className="attachment">
                      <Link to={`https://webapi.entab.info/api/image/${item.attachments[0]}`} target="_blank"><i className="bi bi-paperclip"></i></Link>
                    </div>
                  </div>
                </div>
              ))
          
              :emptyArray.map((item, index) => (
                <div key={index} className="newsCount">
                  <div className="news-block">
                    <div className="news-head">
                      <h4>{item.day} | {item.month}</h4>
                      <h3>{item.title}</h3>
                    </div>
                    <div className="news-content">
                      <p>{item.description}</p>
                    </div>
                    <div className="attachment">
                      <Link to={item.attachments} target="_blank"><i className="bi bi-paperclip"></i></Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default News;
