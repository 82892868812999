import React from 'react'
import { Link } from 'react-router-dom'
const HomeSlider = () => {
  return (
   <>
   <div className="slider-sec">
   <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"> 
   <div className="row"> 
   <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel"> 
   <div className="carousel-indicators">
   <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button> 
   <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>  
   <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>    
   <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button> 
   <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button> 
   </div> 
   <div className="carousel-inner"> 
   <div className="carousel-item active" data-bs-interval="2000">
    <img src="https://webapi.entab.info/api/image/STMST/public/Images/sld.jpg" className="d-block w-100" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>
    <div className="slider-info">
    <h4>Building  <span> Leaders of Tomorrow </span> </h4>
   
    </div>
    </div>   
    <div className="carousel-item" data-bs-interval="2000">
    <img src="https://webapi.entab.info/api/image/STMST/public/Images/sld3.jpg" className="d-block w-100" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
    <div className="slider-info">
    <h4>Where dreams are nurtured and <span> futures are shaped </span>   </h4>
  
    </div>
    </div> 
    <div className="carousel-item" data-bs-interval="2000">
    <img src="https://webapi.entab.info/api/image/STMST/public/Images/sld4.jpg" className="d-block w-100" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
    <div className="slider-info">
    <h4>Bringing     <span> joy to the world of technology</span>  </h4>
    <div className="slider-info-text">
      <h5>.Nelson Mandela</h5>
  </div>
    </div>
    </div> 
    <div className="carousel-item" data-bs-interval="2000">
    <img src="https://webapi.entab.info/api/image/STMST/public/Images/sld1.JPG" className="d-block w-100" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
    <div className="slider-info">
    <h4>Education <span>is the</span> most powerful weapon <span> which you can use to change the</span> world</h4>
    <div className="slider-info-text">
      <h5>.Nelson Mandela</h5>
  </div>
    </div>
    </div> 
   <div className="carousel-item" data-bs-interval="2000">
    <img src="https://webapi.entab.info/api/image/STMST/public/Images/sld2.JPG" className="d-block w-100" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> 
    <div className="slider-info">
    <h4>Education <span>is the</span> most powerful weapon <span> which you can use to change the</span> world</h4>
    <div className="slider-info-text">
      <h5>.Nelson Mandela</h5>
  </div>
    </div>
    </div> 
    </div> <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> <span className="visually-hidden">Previous</span> </button> <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> <span className="visually-hidden">Next</span> </button> 
   </div> 
       
    {/*     <div className="slider-info">
   <div id="carouselExampleCaptions1" className="carousel slide" data-bs-ride="carousel1"> 
   <div className="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button> 
      <button type="button" data-bs-target="#carouselExampleCaptions1" data-bs-slide-to="1" aria-label="Slide 2"></button>  
   </div> 
   <div className="carousel-inner"> 
    
   <div className="carousel-item active" data-bs-interval="2000">
   <h4>Education <span>is the</span> most powerful weapon <span> which you can use to change the</span> world</h4>
    <div className="slider-info-text">
        <h5>.Nelson Mandela</h5>
    </div>
         
   
</div>
     
   <div className="carousel-item" data-bs-interval="2000">
    
         
         <h4>Education <span>is the</span> most powerful weapon <span> which you can use to change the</span> world</h4>
         <div className="slider-info-text">
         <h5>.Nelson Mandela</h5>
     </div>
     </div>
    
   </div> 
   </div> 
  </div>*/}
    <div className="kindergarden">
    <Link to="PreSchool"> 
        <div className="kindergardenimg">
           <img src="https://webapi.entab.info/api/image/STMST/public/Images/kindergardenlogo.png" className="img-fluid"/>
            
         </div>
         <h6> St. Mary’s Convent Pre School Faridabad</h6>
           <Link to="PreSchool"> <img src="https://webapi.entab.info/api/image/STMST/public/Images/arrowicn.png" className="img-fluid"/></Link>
           </Link>
    </div>
   </div>
   </div>
   </div>
   </>
  )
}

export default HomeSlider
