import Home from './Pages/Home'
import AboutUs from './Pages/AboutUs'
import Admission from './Pages/Admission'
import OurGoals from './Pages/OurGoals'
import PrincipalMessage from './Pages/PrincipalMessage'
import SchoolTiming from './Pages/SchoolTiming'
import SchoolUniform from './Pages/SchoolUniform'
import Discipline from './Pages/Discipline'
import ActivityPlanner from './Pages/ActivityPlanner'
import FeeRules from './Pages/FeeRules'
import ModeOfPayment from './Pages/ModeOfPayment'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from './Component/Footer';
import Header from './Component/Header';
import FeeStructure from './Pages/FeeStructure'
import VideoGallery from './Pages/VideoGallery'
import PhotoGallery from './Pages/PhotoGallery'
import SubGallery from './Pages/SubGallery'
import News from './Pages/News'
import Events from './Pages/Events'
import Infrastructure from './Pages/Infrastructure'
import Pphelp from './Pages/Pphelp'
import ErpSoftware from './Pages/ErpSoftware'
import ContactUs from './Pages/ContactUs'
import Topperx from './Pages/Topperx'
import TopperXII from './Pages/TopperXII'
import CBSEDisclosure from './Pages/CBSEDisclosure'
import 'lightbox.js-react/dist/index.css'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import PreSchool from './Pages/PreSchool'
import PreAdmission from './Pages/PreAdmission'
import InterSchoolQuizCompetition from './Pages/InterSchoolQuizCompetition'





function App() {
  return (
    <>
      <BrowserRouter>
        <Header></Header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="AboutUs" element={<AboutUs />} />
          <Route path="Admission" element={<Admission />} />
          <Route path="OurGoals" element={<OurGoals />} />
          <Route path="PrincipalMessage" element={<PrincipalMessage />} />
          <Route path="SchoolTiming" element={<SchoolTiming />} />
          <Route path="SchoolUniform" element={<SchoolUniform />} />
          <Route path="Discipline" element={<Discipline />} />
          <Route path="ActivityPlanner" element={<ActivityPlanner />} />
          <Route path="FeeRules" element={<FeeRules />} />
          <Route path="FeeStructure" element={<FeeStructure />} />
          <Route path="ModeOfPayment" element={<ModeOfPayment />} />
          <Route path="VideoGallery" element={<VideoGallery />} />
          <Route path="PhotoGallery" element={<PhotoGallery />} />
          <Route path="SubGallery" element={<SubGallery />} />
          <Route path="News" element={<News />} />
          <Route path="Events" element={<Events />} />
          <Route path="Infrastructure" element={<Infrastructure />} />
          <Route path="Pphelp" element={<Pphelp />} />
          <Route path="ErpSoftware" element={<ErpSoftware />} />
          <Route path="ContactUs" element={<ContactUs />} />
          <Route path="Topperx" element={<Topperx />} />
          <Route path="TopperXII" element={<TopperXII />} />
          <Route path="CBSEDisclosure" element={<CBSEDisclosure />} />
          <Route path="PreSchool" element={<PreSchool />} />
          <Route path="PreAdmission" element={<PreAdmission />} />
          <Route path="InterSchoolQuizCompetition" element={<InterSchoolQuizCompetition />} />

        </Routes>
        <Footer></Footer>
      </BrowserRouter>
    </>
  )
}

export default App;
