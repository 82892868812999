import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { getTopper } from '../Service/Api';

   const TopperXII = ({ data: propData }) => {

   const [data, setData] = useState(propData || []);

   useEffect(() => {
     const fetchData = async () => {
       try {
         const topperData = await getTopper();
         setData(topperData);
       } catch (error) {
         console.error('Error fetching data:', error);
       }
     };
     if (!propData) {
       fetchData();
     }
   }, [propData]);
 
   useEffect(() => {
     console.log("events_data", data);
   }, [data]);
 
   const emptyArray = [
     { title: "Event Title", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/STMST/public/Images/events.jpg" },
     { title: "Event Title", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/STMST/public/Images/events.jpg" },
     { title: "Event Title", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/STMST/public/Images/events.jpg" },
     { title: "Event Title", time: "10", date: "DD/MM/YYYY", venue: "School", description: "Stay tuned for latest updates", attachments: "https://webapi.entab.info/api/image/STMST/public/Images/events.jpg" },
 ];
 

  return (
    <>
    <div className="innerslide">
        <ul className="breadcrumb">
                    <li><Link to="/home"> Home</Link> </li>
          <li>Topper XII</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h1>Topper XII</h1>
            </div>
            <div className="col-lg-6">
              <div className="chooseyear">
                <select id="chooseddlYear">
                  <option value="Two">2024</option>                  
                </select>
              </div>
            </div>
          </div>
          <div className="clr"></div>
          <div id="showTwo" className="myDiv1 selected_year">
            <div className="topperssec">
              {data?.length > 0 ? data?.filter((item) => item.class === "XII").map((item, index) => (
                <div key={index} className="topperdiv">
                  <div className="topperper"><p>{item.division}</p></div>
                  <div className="topperdivimg">
                    <img src={`https://webapi.entab.info/api/image/${item.attachments}`} className="img-fluid" alt="St. Mary's School" />
                  </div>
                  <div className="topperdivdesc">
                    <p className="toppername">{item.name}<span>{item.stream}</span></p>
                  </div>
                </div>
              )) : (
               emptyArray.map((data, index) => (
                  <div key={index} className="topperdiv">
                  <div className="topperper"><p>{data.division}</p></div>
                  <div className="topperdivimg">
                    <img src={data.attachments} className="img-fluid" alt="St. Mary's School" />
                  </div>
                  <div className="topperdivdesc">
                    <p className="toppername">{data.name}<span>{data.stream}</span></p>
                  </div>
                </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default TopperXII
