import React from 'react'
import { Link } from 'react-router-dom'
const FeeStructure = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li>
     <li> Admission </li>
     <li> Fee Structure </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1> Fee Structure </h1>
         </div>
         <iframe src="https://webapi.entab.info/api/image/STMST/public/pdf/Fee-Structure.pdf" height="800" width="100%">  </iframe>
     </div>
  
</div>
</div>
    </>
  )
}

export default FeeStructure
