import React from 'react'
import { Link } from 'react-router-dom' 
import { SlideshowLightbox } from 'lightbox.js-react'
const InterSchoolQuizCompetition = () => {
  return (
    <>
    <div className="innerslide">
    <ul className="breadcrumb">
     <li><Link to="/"> Home</Link> </li>
     <li>  Co-curricular  </li>
     <li> Inter School Quiz Competition </li>
 </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
           <div className="col-lg-12">
              <h1> Inter School Quiz Competition </h1>
         </div>
     </div>
     <div className="row">
     <div className="col-lg-12">
     <p><b> St. Mary’s Convent Sr. Sec. School </b> believes that positive competitions provide a great platform to showcase the talent, knowledge and skills. Achievements through competitions can leave an indelible impression on one’s mind. Keeping this in mind, an inter school quiz competition was organized on 9th Nov in the school auditorium. The school was pleased to host <b>24 varied schools of Faridabad.</b> All the students participated in the preliminary rounds and the best performing teams were selected for the semi final and final rounds. A wide range of questions related to varied subjects were included by the quiz master Mr. Titash Bannerjee.  </p>

     <p>The event began with the welcoming of the gathering and the lamp lightening. Cultural programmes were captivating and enthralling. The solo and the group dances were the feast to the eyes. Fr. Mathew welcomed all the teachers and the participants coming from all the corners of Faridabad. He encouraged them by impressing upon them that they should never leave the curiosity and the eagerness to do the best they can irrespective of what the result unfolds. Vote of thanks was proposed by the Dean of the school, Mr. G.S. Bhadauria.  </p>
     
     <p>The first prize was bagged by the host school St. Mary’s Convent Sr. Sec. School. The participants were   <b>Namit Duha, Class- VIII-A and Akshat Rao, Class- VII-A.</b> The Second position was bagged by Modern Delhi International School, Sector- 87 and the third prize was won by Modern Delhi International School, Sector- 89. </p>
     
     <p>The prizes were handed out by Sr. Principal (Sr. Tomsy SIC), Fr. Mathew and the Dean of the school Mr. G.S. Bhadauria. The programme culminated with the National Anthem. </p>
           </div> 
     
     
                <div className="col-lg-3"><img src="https://webapi.entab.info/api/image/STMST/public/Images/QuizCompetition.jpg" className="img-fluid mainimg" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/></div>
                <div className="col-lg-3"><img src="https://webapi.entab.info/api/image/STMST/public/Images/QuizCompetition1.jpg" className="img-fluid mainimg" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/></div> 
                <div className="col-lg-3"><img src="https://webapi.entab.info/api/image/STMST/public/Images/QuizCompetition2.jpg" className="img-fluid mainimg" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/> </div>
                <div className="col-lg-3"><img src="https://webapi.entab.info/api/image/STMST/public/Images/QuizCompetition3.jpg" className="img-fluid mainimg" alt="St. Mary’s Convent Sr. Sec. School, Tigaon"/>  </div>
            </div>
        </div>
 
           
 
</div>
    </>
  )
}

export default InterSchoolQuizCompetition
